import React, { FC } from "react"

import Layout from "../components/layout"
import { PageProps } from "gatsby"

const AboutPage: FC<PageProps> = () => (
  <Layout>
    <div className="row">
      <div className="col-md-2">
        <img alt="me" src="/files/pics/me.png" className="img-responsive img-thumbnail" />
      </div>

      <div className="col-md-8">
        <p>
          Welcome to my private blog. I'm Manuel Mauky, living in the small town{" "}
          <a href="http://en.wikipedia.org/wiki/Görlitz">Görlitz</a> in the east of germany.
        </p>

        <p>
          I'm working as a software developer at{" "}
          <a href="https://www.zeiss.com/digital-innovation/home.html">ZEISS Digital Innovation</a> where I deal with
          all parts of application development but with a focus on the frontend. In the past I was working with Java
          both on the Server (JavaEE, JSF, JPA, CDI) and on the Client (JavaFX). Nowadays, I'm working with JavaScript,
          TypeScript, React and Web-Components.
        </p>
        <p>
          My "main" programming language was Java for a long time and most of the blog posts will reflect that. But for
          some time now I'm doing most of my programming with TypeScript and JavaScript. What I like about the
          JavaScript community is that they don't hesitate to rethink old "best practises" and to find new ways of
          thinking about applications. And sometimes this leads to really interesting new technologies like{" "}
          <em>React</em>, <em>Redux</em> and <em>GraphQL</em>.
        </p>

        <p>
          In general I'm interested in programming languages and I'm trying to learn new languages from time to time.
          One language I was falling in love with is Haskell. Learning it was invaluable because I've learned so many
          things about language design, semantics of programming languages, functional programming and programming in
          general. However, I'm not good enough to build real programs in Haskell and this is OK for me.
        </p>
        <p>
          I'm a big fan of functional programming and am interested in connected topics like category theory. And while
          most of the time I'm using programming languages that are not functional languages (i.e. Java and TypeScript),
          I try to use functional patterns and a functional programming style as much as possible.
        </p>

        <p>
          Besides my job I was studying at the{" "}
          <a href="http://www.f-ei.hszg.de">University of Applied Sciences Zittau/Görlitz</a>
          and finished with a masters degree in computer science. My favorite subjects at the university were{" "}
          <i>theoretical computer science</i> and concepts of software development. The topic of my master thesis was
          "Über die Kombination von{" "}
          <a href="https://en.wikipedia.org/wiki/Command%E2%80%93query_separation#Command_query_responsibility_segregation">
            CQRS
          </a>{" "}
          und <a href="https://en.wikipedia.org/wiki/Reactive_programming">Reactive Programming</a>" (engl. "About the
          combination of CQRS and Reactive Programming") in which I explored how you can implement the "Command Query
          Responsibility Segregation" architecture pattern with techniques from the "reactive programming" paradigm.
        </p>

        <p>
          In this blog I'm writing about software development with a focus on functional programming, TypeScript,
          JavaScript, Web and (in the past) Java EE and JavaFX.
        </p>

        <p>
          You can find my projects on my github account:
          <a href="https://github.com/manuel-mauky">https://github.com/manuel-mauky</a>
        </p>

        <p>
          I'm a founding member, coordinator and regular speaker at the{" "}
          <a href="http://www.jug-gr.de">Java User Group Görlitz</a>. I'm also speaking at other meet-ups and
          conferences.
        </p>

        <p>
          You can also find me on Twitter: <a href="https://twitter.com/manuel_mauky">@manuel_mauky</a>.
        </p>

        <p>
          About the domain "lestard": It was a nickname I was using back in the days when I was playing computer games
          at local LAN parties. It doesn't have a deeper meaning.
        </p>
      </div>
    </div>
  </Layout>
)

export default AboutPage
